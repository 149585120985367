import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectDeliveries = state => state.deliveries

export const selectDeliveriesList = createSelector(
  selectDeliveries,
  propOr([], 'list')
)

export const selectDefaultFilteredList = createSelector(
  selectDeliveries,
  propOr([], 'defaultFilteredList')
)

export const selectCurrentDelivery = createSelector(
  selectDeliveries,
  propOr([], 'currentDelivery')
)

export const selectDeliveriesListPagination = createSelector(
  selectDeliveries,
  propOr([], 'pagination')
)

export const selectDefaultFilteredListPagination= createSelector(
  selectDeliveries,
  propOr([], 'defaultFilteredListPagination')
)

export const selectDeliveryItems = createSelector(
  selectDeliveries,
  propOr([], 'deliveryItems')
)

export const selectDeliveryItemsPagination = createSelector(
  selectDeliveries,
  propOr([], 'deliveryItemsPagination')
)
