import {
  fetchLocationHistoryRoutine,
  fetchLocationsRoutine,
  fetchSingleLocationRoutine
} from 'modules/locations/ducks/actions'

const defaultState = {
  list: [],
  currentLocation: {},
  pagination: null,
  locationHistoryList: [],
  locationHistoryListPagination: null
}

const locationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchLocationsRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        pagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    case fetchSingleLocationRoutine.SUCCESS:
      return {
        ...state,
        currentLocation: action.payload
      }
    case fetchLocationHistoryRoutine.SUCCESS:
      return {
        ...state,
        locationHistoryList: action.payload.data,
        locationHistoryListPagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    default:
      return state
  }
}

export default locationsReducer
