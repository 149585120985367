import React, { useEffect } from 'react'
import Table from 'components/Table'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWarehousesRoutine } from 'modules/warehouses/ducks/actions'
import { selectWarehousesList } from 'modules/warehouses/ducks/selectors'
import EditWarehouseModal from 'modules/warehouses/components/EditWarehouseModal'
import RemoveWarehouseModal from 'modules/warehouses/components/RemoveWarehouseModal'
import { useHistory } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import { fetchZonesRoutine } from 'modules/zones/ducks/actions'
import { changeQuery } from 'utils/navigation'
import { ActionsContainer } from 'theme/wrappers'
import { propOr } from 'ramda'

const defaultFilters = {
  page: 1,
  limit: 20
}

const WarehousesTable = () => {
  const dispatch = useDispatch()
  const list = useSelector(selectWarehousesList)
  const { location: { search } } = useHistory()

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchZonesRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  useEffect(() => {
    dispatch(fetchWarehousesRoutine())
  }, [])

  const headers = [
    { children: 'Nazwa magazynu' },
    { children: 'Subiekt ID' },
    { children: '', align: 'right' }
  ]

  const rows = list.map(row => ({
    cells: [
      { children: row.name },
      { children: propOr('---', 'subiektId', row) },
      {
        children: (
          <ActionsContainer>
            <EditWarehouseModal warehouse={row} />
            <RemoveWarehouseModal warehouse={row} />
          </ActionsContainer>
        ),
        align: 'right',
        preventRedirect: true
      }
    ]
  }))

  return <Table headers={headers} rows={rows} />
}

export default WarehousesTable
