import {
  fetchDefaultFilteredDeliveriesListRoutine,
  fetchDeliveriesListRoutine,
  fetchSingleDeliveryRoutine,
  fetchDeliveryItemsRoutine
} from 'modules/delivery/ducks/actions'

const defaultState = {
  list: [],
  pagination: null,
  defaultFilteredList: [],
  defaultFilteredListPagination: null,
  currentDelivery: {},
  deliveryItems: [],
  deliveryItemsPagination: null
}

const deliveriesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchDeliveriesListRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        pagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    case fetchDefaultFilteredDeliveriesListRoutine.SUCCESS:
      return {
        ...state,
        defaultFilteredList: action.payload.data,
        defaultFilteredListPagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    case fetchSingleDeliveryRoutine.SUCCESS:
      return {
        ...state,
        currentDelivery: action.payload
      }
    case fetchDeliveryItemsRoutine.SUCCESS:
      return {
        ...state,
        deliveryItems: action.payload.data,
        deliveryItemsPagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    default:
      return state
  }
}

export default deliveriesReducer
