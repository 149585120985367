import React, { useEffect, useMemo } from 'react'
import { fetchFlightControlSaleOrdersRoutine } from 'modules/flightControl/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectFlightControlSaleOrders } from 'modules/flightControl/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { keys } from 'ramda'
import Table from 'components/Table'
import styled from 'styled-components'

const AllOrdersTable = () => {
  const orders = useSelector(selectFlightControlSaleOrders)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFlightControlSaleOrdersRoutine())
  }, [])

  const couriers = useMemo(() => {
    if (isNotNilOrEmpty(orders)) {
      const real = orders.real
      const estimation = orders.estimation
      const courierKeys = keys(real)
      return courierKeys.map(courier => ({
        label: real[courier]?.label,
        real: real[courier],
        estimation: estimation[courier]
      }))
    } else {
      return []
    }
  }, [orders])

  const headers = [
    { children: 'Kurier' },
    { children: 'Wszystkie' },
    { children: '1 SKU' },
    { children: 'Multi SKU' }
  ]

  const rows = couriers.map(courier => ({
    cells: [
      { children: courier.label },
      { children: `${courier.real?.total} (${courier.estimation?.total})` },
      { children: `${courier.real?.singleSku} (${courier.estimation?.singleSku})` },
      { children: `${courier.real?.multiSku} (${courier.estimation?.multiSku})` }
    ]
  }))

  return (
    <Wrapper>
      <Header>Aktualna ilość paczek</Header>
      <Table
        rows={rows}
        headers={headers}
      />
    </Wrapper>
  )
}

export default AllOrdersTable

const Wrapper = styled.div`
  max-width: 400px;
  min-width: 400px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f0f3f4;
`

const Header = styled.div`
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 18px;
`
