import Table from 'components/Table'
import { pathOr, propOr } from 'ramda'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import { fetchWarehouseStateRoutine } from '../ducks/actions'
import {
  selectWarehouseState,
  selectWarehouseStatePagination
} from '../ducks/selectors'

const defaultFilters = {
  page: 1,
  limit: 20
}

const WarehouseStateTable = () => {
  const {
    location: { search }
  } = useHistory()
  const dispatch = useDispatch()
  const list = useSelector(selectWarehouseState)
  const pagination = useSelector(selectWarehouseStatePagination)

  useEffect(() => {
    isNotNilOrEmpty(search)
      ? dispatch(fetchWarehouseStateRoutine({ query: search }))
      : changeQuery(defaultFilters)
  }, [search])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'Lokalizacja' },
    { children: 'Strefa' },
    { children: 'JM' },
    { children: 'EAN' },
    { children: 'Nazwa towaru' },
    { children: 'Ilość' },
    { children: 'Magazyn' }
  ]

  const rows = list.map(row => {
    const locations = propOr('---', 'locations', row)
    return {
      cells: [
        {
          children: (
            <>
              {isNotNilOrEmpty(locations) ? (
                locations.map(item => {
                  return (
                    <div key={`${propOr(String(Math.random() * 1000), 'id', item)}`}>
                      {propOr('---', 'location', item)}
                    </div>
                  )
                })
              ) : (
                <div>---</div>
              )}
            </>
          )
        },
        {
          children: (
            <>
              {isNotNilOrEmpty(locations) ? (
                locations.map(item => (
                  <div key={`${pathOr(String(Math.random() * 1000), ['location', 'id'], item)}`}>
                    {propOr('---', 'zone', item)}
                  </div>
                ))
              ) : (
                <div>---</div>
              )}
            </>
          )
        },
        {
          children: (
            <>
              {isNotNilOrEmpty(locations) ? (
                locations.map(item => (
                  <div key={`${pathOr(String(Math.random() * 1000), ['location', 'id'], item)}`}>
                    {propOr('---', 'stockUnit', item)}
                  </div>
                ))
              ) : (
                <div>---</div>
              )}
            </>
          )
        },
        { children: propOr('---', 'ean', row) },
        { children: propOr('---', 'name', row) },
        {
          children: (
            <>
              {isNotNilOrEmpty(locations) ? (
                locations.map(item => (
                  <div key={`${propOr(String(Math.random() * 1000), 'id', item)}`}>{propOr(0, 'quantity', item)}</div>
                ))
              ) : (
                <div>---</div>
              )}
            </>
          )
        },
        {
          children: (
            <>
              {isNotNilOrEmpty(locations) ? (
                locations.map(item => (
                  <div key={`${pathOr(String(Math.random() * 1000), ['location', 'id'], item)}`}>
                    {propOr('---', 'warehouse', item)}
                  </div>
                ))
              ) : (
                <div>---</div>
              )}
            </>
          )
        }
      ]
    }
  })

  return (
    <Table
      headers={headers}
      rows={rows}
      pagination={pagination}
      onPageChange={handlePageChange}
    />
  )
}

export default WarehouseStateTable
