import { pathOr, propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectFlightControl = state => state.flightControl

export const selectFlightControlSummary = createSelector(
  selectFlightControl,
  propOr({}, 'summary')
)

export const selectFlightControlSaleOrders = createSelector(
  selectFlightControl,
  propOr({}, 'saleOrders')
)

export const selectPackedOrders = createSelector(
  selectFlightControl,
  propOr({}, 'packed')
)

export const selectForPackingOrders = createSelector(
  selectFlightControl,
  propOr({}, 'for_packing')
)

export const selectAllocatedOrders = createSelector(
  selectFlightControl,
  propOr({}, 'allocated')
)

export const selectCollectedOrders = createSelector(
  selectFlightControl,
  propOr({}, 'collected')
)

export const selectPackerStats = createSelector(
  selectFlightControl,
  propOr({}, 'packer')
)

export const selectPickerStats = createSelector(
  selectFlightControl,
  propOr({}, 'picker')
)

export const selectConnectorStats = createSelector(
  selectFlightControl,
  propOr({}, 'connector')
)

export const selectFlightControlSettings = createSelector(
  selectFlightControl,
  propOr({}, 'settings')
)

export const selectFlightControlCutOffTime = createSelector(
  selectFlightControl,
  pathOr('', ['settings', 'flightControlEndOfPacking'])
)
