import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectUsers = state => state.users

export const selectUsersList = createSelector(selectUsers, propOr([], 'list'))

export const selectUsersListByRoles = createSelector(
  selectUsers,
  propOr([], 'listByRoles')
)

export const selectUsersListPagination = createSelector(
  selectUsers,
  propOr(
    {
      totalPages: 1,
      perPage: 20,
      itemsCount: 0
    },
    'pagination'
  )
)

export const selectUsersLogs = createSelector(selectUsers, propOr([], 'logs'))
