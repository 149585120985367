import {
  fetchCouriersGroupsListRoutine,
  fetchCouriersListRoutine,
  fetchSingleCourierGroupRoutine,
  fetchUngroupedCouriersListRoutine
} from 'modules/couriers/ducks/actions'

const defaultState = {
  list: [],
  pagination: null,
  groupsList: [],
  groupsPagination: null,
  ungroupedList: [],
  currentGroup: {}
}

const couriersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchCouriersListRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        pagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    case fetchUngroupedCouriersListRoutine.SUCCESS:
      return {
        ...state,
        ungroupedList: action.payload.data
      }
    case fetchCouriersGroupsListRoutine.SUCCESS:
      return {
        ...state,
        groupsList: action.payload.data,
        groupsPagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    case fetchSingleCourierGroupRoutine.SUCCESS:
      return {
        ...state,
        currentGroup: action.payload
      }
    default:
      return state
  }
}

export default couriersReducer
