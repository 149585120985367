import {
  fetchChangesHistoryRoutine,
  fetchProductsRoutine,
  fetchShiftHistoryRoutine,
  fetchSingleProductRoutine
} from 'modules/products/ducks/actions'

const defaultState = {
  list: [],
  currentProduct: {},
  pagination: null,
  shiftHistoryList: [],
  changesHistoryList: [],
  shiftHistoryListPagination: null,
  changesHistoryListPagination: null
}

const productsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchProductsRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        pagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    case fetchSingleProductRoutine.SUCCESS:
      return {
        ...state,
        currentProduct: action.payload
      }
    case fetchShiftHistoryRoutine.SUCCESS:
      return {
        ...state,
        shiftHistoryList: action.payload.data,
        shiftHistoryListPagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    case fetchChangesHistoryRoutine.SUCCESS:
      return {
        ...state,
        changesHistoryList: action.payload.data,
        changesHistoryListPagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    default:
      return state
  }
}

export default productsReducer
