import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectSuppliers = state => state.suppliers

export const selectSuppliersList = createSelector(
  selectSuppliers,
  propOr([], 'list')
)

export const selectSuppliersListPagination = createSelector(
  selectSuppliers,
  propOr({
    totalPages: 1,
    perPage: 20,
    itemsCount: 0
  }, 'pagination')
)
