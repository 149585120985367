import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import * as stockUnitsService from 'services/StockUnitsService'
import { toast } from 'react-hot-toast'
import { isEmpty, pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getApiErrors } from 'utils/errors'

export const fetchCompletionListsForStockUnitRoutine = createRoutine(
  'FETCH_COMPLETION_LISTS_FOR_STOCK_UNIT'
)
export const setCurrentPackingOrderRoutine = createRoutine(
  'SET_CURRENT_PACKING_ORDER'
)
export const clearCurrentPackingOrderRoutine = createRoutine(
  'CLEAR_CURRENT_PACKING_ORDER'
)
export const clearCurrentStockUnitOrdersRoutine = createRoutine(
  'CLEAR_CURRENT_STOCK_UNIT_ORDERS'
)
export const setPackedItemQuantityRoutine = createRoutine(
  'SET_PACKED_ITEM_QUANTITY'
)
export const setPackedItemEanRoutine = createRoutine('SET_PACKED_ITEM_EAN')

// ACTIONS
function * fetchCompletionListsForStockUnit ({ payload }) {
  const { callback, code, withToast, setStatus } = payload
  yield put(fetchCompletionListsForStockUnitRoutine.request())
  try {
    const { data } = yield call(
      stockUnitsService.fetchStockUnitCompletionsLists,
      { code }
    )
    const orders = pathOr(null, ['data', 'orders'], data)
    if (isEmpty(orders)) {
      yield put(
        fetchCompletionListsForStockUnitRoutine.success({
          data: data.data,
          code,
        })
      )
      callback && callback()
    } else if (
      !['for_packing', 'partially_packed'].includes(data.data?.status?.value)
    ) {
      withToast &&
        toast.error(
          isNotNilOrEmpty(data.data)
            ? `Nieprawidłowy status listy '${data.data?.status?.label}'`
            : 'Nie odnaleziono jednostki lub jest ona pusta',
          {
            duration: 5000,
            style: {
              fontSize: 22
            }
          }
        )
      setStatus && setStatus('start')
      yield put(fetchCompletionListsForStockUnitRoutine.failure())
    } else if (isNotNilOrEmpty(orders)) {
      yield put(
        fetchCompletionListsForStockUnitRoutine.success({
          data: data.data,
          code
        })
      )
      const partiallyPackedOrder = orders.find(
        order => pathOr('', ['status', 'value'], order) === 'partially_packed'
      )
      if (isNotNilOrEmpty(partiallyPackedOrder)) {
        yield put(setCurrentPackingOrderRoutine(partiallyPackedOrder))
      }
      callback && callback()
    } else {
      withToast &&
        toast.error('Nie odnaleziono jednostki lub jest ona pusta', {
          duration: 5000,
          style: {
            fontSize: 22
          }
        })
      setStatus && setStatus('start')
      yield put(fetchCompletionListsForStockUnitRoutine.failure())
    }
  } catch (e) {
    yield put(fetchCompletionListsForStockUnitRoutine.failure(e))
    withToast &&
      toast.error(getApiErrors(e), {
        duration: 5000,
        style: {
          fontSize: 22
        }
      })
    console.error(e)
  }
}

function * setCurrentPackingOrder ({ payload }) {
  yield put(setCurrentPackingOrderRoutine.success(payload))
}

function * clearCurrentPackingOrder ({ payload }) {
  yield put(clearCurrentPackingOrderRoutine.success(payload))
}

function * clearCurrentStockUnitOrders ({ payload }) {
  yield put(clearCurrentStockUnitOrdersRoutine.success(payload))
}

function * setPackedItemQuantity ({ payload }) {
  yield put(setPackedItemQuantityRoutine.success(payload))
}

function * setPackedItemEan ({ payload }) {
  yield put(setPackedItemEanRoutine.success(payload))
}

// WATCHERS
export function * fetchCompletionListsForStockUnitWatcher () {
  yield takeLatest(
    fetchCompletionListsForStockUnitRoutine.TRIGGER,
    fetchCompletionListsForStockUnit
  )
}

export function * setCurrentPackingOrderWatcher () {
  yield takeLatest(
    setCurrentPackingOrderRoutine.TRIGGER,
    setCurrentPackingOrder
  )
}

export function * clearCurrentPackingOrderWatcher () {
  yield takeLatest(
    clearCurrentPackingOrderRoutine.TRIGGER,
    clearCurrentPackingOrder
  )
}

export function * clearCurrentStockUnitOrdersWatcher () {
  yield takeLatest(
    clearCurrentStockUnitOrdersRoutine.TRIGGER,
    clearCurrentStockUnitOrders
  )
}

export function * setPackedItemQuantityWatcher () {
  yield takeLatest(setPackedItemQuantityRoutine.TRIGGER, setPackedItemQuantity)
}

export function * setPackedItemEanWatcher () {
  yield takeLatest(setPackedItemEanRoutine.TRIGGER, setPackedItemEan)
}

// SAGAS
export const packingSagas = [
  fork(fetchCompletionListsForStockUnitWatcher),
  fork(setCurrentPackingOrderWatcher),
  fork(clearCurrentPackingOrderWatcher),
  fork(clearCurrentStockUnitOrdersWatcher),
  fork(setPackedItemQuantityWatcher),
  fork(setPackedItemEanWatcher)
]
