import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDeliveriesList,
  selectDeliveriesListPagination
} from 'modules/delivery/ducks/selectors'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { pathOr, propOr } from 'ramda'
import Table from 'components/Table'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useHistory } from 'react-router-dom'
import { fetchDeliveriesListRoutine } from 'modules/delivery/ducks/actions'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: 'scheduled_for'
}

const DeliveryOrdersLogsTable = () => {
  const deliveries = useSelector(selectDeliveriesList)
  const pagination = useSelector(selectDeliveriesListPagination)

  const {
    location: { search }
  } = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    isNotNilOrEmpty(search)
      ? dispatch(fetchDeliveriesListRoutine({ query: search }))
      : changeQuery(defaultFilters)
  }, [search])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'ID', sort: 'ordinal_no' },
    { children: 'Nr dokumentu', sort: 'document_no' },
    { children: 'Planowana data dostawy', sort: 'scheduled_for' },
    { children: 'Status' }
  ]

  const rows = deliveries.map(row => {
    const subiektLogs = propOr([], 'subiektLogs', row)
    return {
      redirectPath: `/delivery/${row.id}`,
      cells: [
        { children: propOr('---', 'ordinalNo', row) },
        { children: propOr('---', 'documentNo', row) },
        { children: formatDate(row.scheduledFor, DATE_FORMATS.dashed) },
        {
          children: subiektLogs.map(log => (
            <div key={log.id}>{pathOr('---', ['status', 'label'], log)}</div>
          ))
        },
      ]
    }
  })

  return (
    <>
      <Table
        rows={rows}
        headers={headers}
        pagination={pagination}
        onPageChange={handlePageChange}
      />
    </>
  )
}

export default DeliveryOrdersLogsTable
