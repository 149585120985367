import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectStocktakingOrdersList,
  selectStocktakingOrdersPagination
} from 'modules/stocktakingOrders/ducks/selectors'
import { useHistory } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import Table from 'components/Table'
import styled from 'styled-components'
import { fetchStocktakingOrdersRoutine } from 'modules/stocktakingOrders/ducks/actions'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { pathOr, propOr } from 'ramda'
import { STOCKTAKING_ORDERS_STATUSES } from 'utils/stocktakingOrders'
import { ActionsContainer } from 'theme/wrappers'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: '-created_at',
  filter: {
    status: 'finished,cancelled'
  }
}

const FinishedStocktakingOrdersTable = () => {
  const list = useSelector(selectStocktakingOrdersList)
  const pagination = useSelector(selectStocktakingOrdersPagination)
  const dispatch = useDispatch()
  const {
    location: { search }
  } = useHistory()

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchStocktakingOrdersRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  useEffect(() => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      filter: {
        ...defaultFilters.filter,
        ...propOr({}, 'filter', parsedQuery),
        status: 'cancelled,finished'
      }
    })
  }, [])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'Data zlecenia', sort: 'created_at' },
    { children: 'Data zakończenia' },
    { children: 'Osoba zlecająca' },
    { children: 'Przeliczone przez' },
    { children: 'Lokalizacja' },
    { children: 'EAN' },
    { children: 'Nazwa produktu' },
    { children: 'Ilość przed' },
    { children: 'Ilość po' },
    { children: 'Różnica' },
    { children: 'Powód' },
    { children: 'Komentarz' },
    { children: 'Status' },
    { children: '' }
  ]

  const rows =
    list &&
    list.map(row => {
      const products = propOr([], 'products', row)

      return {
        cells: [
          { children: formatDate(row.createdAt, DATE_FORMATS.dashedWithTime) },
          { children: formatDate(row.assignedAt, DATE_FORMATS.dashedWithTime) },
          { children: pathOr('---', ['orderedBy', 'username'], row) },
          { children: pathOr('---', ['checkedBy', 'username'], row) },
          { children: pathOr('', ['location', 'name'], row) },
          {
            children: (
              <>
                {products.map(product => (
                  <ProductItem
                    isHighlighted={
                      pathOr(0, ['expectedQuantity'], product) !==
                      pathOr(0, ['realQuantity'], product)
                    }
                    key={`ean-${product.id}`}
                  >
                    {pathOr('--', ['product', 'ean'], product)}
                  </ProductItem>
                ))}
              </>
            )
          },
          {
            children: (
              <>
                {products.map(product => (
                  <ProductItem
                    isHighlighted={
                      pathOr(0, ['expectedQuantity'], product) !==
                      pathOr(0, ['realQuantity'], product)
                    }
                    key={`name-${product.id}`}
                  >
                    {pathOr('--', ['product', 'name'], product)}
                  </ProductItem>
                ))}
              </>
            )
          },
          {
            children: (
              <>
                {products.map(product => (
                  <ProductItem
                    isHighlighted={
                      pathOr(0, ['expectedQuantity'], product) !==
                      pathOr(0, ['realQuantity'], product)
                    }
                    key={`expected-quantity-${product.id}`}
                  >
                    {pathOr(0, ['expectedQuantity'], product)}
                  </ProductItem>
                ))}
              </>
            )
          },
          {
            children: (
              <>
                {products.map(product => (
                  <ProductItem
                    isHighlighted={
                      pathOr(0, ['expectedQuantity'], product) !==
                      pathOr(0, ['realQuantity'], product)
                    }
                    key={`real-quantity-${product.id}`}
                  >
                    {pathOr(0, ['realQuantity'], product)}
                  </ProductItem>
                ))}
              </>
            )
          },
          {
            children: (
              <>
                {products.map(product => (
                  <ProductItem
                    isHighlighted={
                      pathOr(0, ['expectedQuantity'], product) !==
                      pathOr(0, ['realQuantity'], product)
                    }
                    key={`difference-${product.id}`}
                  >
                    {product
                      ? pathOr(0, ['realQuantity'], product) -
                        pathOr(0, ['expectedQuantity'], product)
                      : ''}
                  </ProductItem>
                ))}
              </>
            )
          },
          {
            children: pathOr('---', ['source', 'label'], row)
          },
          { children: propOr('---', 'comment', row) },
          {
            children:
              STOCKTAKING_ORDERS_STATUSES[propOr('undefined', 'status', row)]
          },
          {
            children: <ActionsContainer />,
            align: 'right'
          }
        ]
      }
    })

  return (
    <Table
      pagination={pagination}
      onPageChange={handlePageChange}
      headers={headers}
      rows={rows}
      emptyState='Brak zakończonych inwentaryzacji'
    />
  )
}

export default FinishedStocktakingOrdersTable

const ProductItem = styled.div`
  color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.colors.error : theme.colors.text};
`
