import { pathOr, propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectProducts = state => state.products

export const selectProductsList = createSelector(
  selectProducts,
  propOr([], 'list')
)

export const selectShiftHistoryList = createSelector(
  selectProducts,
  propOr([], 'shiftHistoryList')
)

export const selectChangesHistoryList = createSelector(
  selectProducts,
  propOr([], 'changesHistoryList')
)

export const selectCurrentProduct = createSelector(
  selectProducts,
  propOr({}, 'currentProduct')
)

export const selectCurrentProductFiles = createSelector(
  selectProducts,
  pathOr([], ['currentProduct', 'files'])
)

export const selectCurrentProductIconLargeUrl = createSelector(
  selectProducts,
  pathOr([], ['currentProduct', 'iconLargeUrl'])
)

export const selectCurrentProductIconSmallUrl = createSelector(
  selectProducts,
  pathOr([], ['currentProduct', 'iconSmallUrl'])
)

export const selectProductsListPagination = createSelector(
  selectProducts,
  propOr(
    {
      totalPages: 1,
      perPage: 20,
      itemsCount: 0
    },
    'pagination'
  )
)

export const selectShiftHistoryListPagination = createSelector(
  selectProducts,
  propOr(
    {
      totalPages: 1,
      perPage: 20,
      itemsCount: 0
    },
    'shiftHistoryListPagination'
  )
)

export const selectChangesHistoryListPagination = createSelector(
  selectProducts,
  propOr(
    {
      totalPages: 1,
      perPage: 20,
      itemsCount: 0
    },
    'changesHistoryListPagination'
  )
)
