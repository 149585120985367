import {
  fetchSaleOrdersRoutine,
  fetchSaleOrdersStatisticsRoutine,
  fetchSingleSaleOrderRoutine,
  fetchStatusLogsRoutine, fetchSuspendedSaleOrdersRoutine
} from 'modules/saleOrders/ducks/actions'

const defaultState = {
  list: [],
  suspendedList: [],
  statusLogList: [],
  currentSaleOrder: {},
  pagination: null,
  suspendedPagination: null,
  statusLogListPagination: null
}

const saleOrdersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchSaleOrdersRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        pagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    case fetchSuspendedSaleOrdersRoutine.SUCCESS:
      return {
        ...state,
        suspendedList: action.payload.data,
        suspendedPagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    case fetchSingleSaleOrderRoutine.SUCCESS:
      return {
        ...state,
        currentSaleOrder: action.payload
      }
    case fetchSaleOrdersStatisticsRoutine.SUCCESS:
      return {
        ...state,
        statistics: action.payload
      }
    case fetchStatusLogsRoutine.SUCCESS:
      return {
        ...state,
        statusLogList: action.payload.data,
        statusLogListPagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    default:
      return state
  }
}

export default saleOrdersReducer
