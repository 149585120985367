import React, { useState } from 'react'
import styled from 'styled-components'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentUnitNumber,
  selectCurrentUnitOrders,
  selectPackedItemEan,
  selectPackedItemQuantity
} from 'modules/packing/ducks/selectors'
import { pathOr } from 'ramda'
import {
  fetchCompletionListsForStockUnitRoutine,
  setCurrentPackingOrderRoutine,
  setPackedItemEanRoutine,
  setPackedItemQuantityRoutine
} from 'modules/packing/ducks/actions'
import { packItem } from 'services/PackingService'
import { getApiErrors } from 'utils/errors'
import Button from 'components/atoms/Button'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined'
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { packingErrorEmitter } from 'utils/packing'

const PackingCurrentStatus = () => {
  const orders = useSelector(selectCurrentUnitOrders)
  const dispatch = useDispatch()
  const selectedQuantity = useSelector(selectPackedItemQuantity)
  const selectedEan = useSelector(selectPackedItemEan)
  const currentUnitNumber = useSelector(selectCurrentUnitNumber)
  const [itemAdded, setItemAdded] = useState(false)

  const handleBlockAdd = () => {
    setItemAdded(true)
    setTimeout(() => setItemAdded(false), 300)
  }

  const handleAddManually = e => {
    e.preventDefault()
    const workstationName = localStorage.getItem('workstation')
    const station = isNotNilOrEmpty(workstationName)
      ? { workstation: workstationName }
      : {}
    handleBlockAdd()

    packItem({
      ...station,
      ean: selectedEan,
      unitNumber: currentUnitNumber,
      quantity: selectedQuantity
    })
      .then(resp => {
        const data = resp.data.data
        dispatch(setCurrentPackingOrderRoutine(data))
        dispatch(setPackedItemQuantityRoutine(1))
        dispatch(
          fetchCompletionListsForStockUnitRoutine({ code: currentUnitNumber })
        )
        dispatch(setPackedItemEanRoutine(''))
      })
      .catch(e => {
        packingErrorEmitter.emit('error', getApiErrors(e))
      })
  }

  const handleIncrementCounter = () => {
    dispatch(setPackedItemQuantityRoutine(selectedQuantity + 1))
  }

  const handleDecrementCounter = () => {
    dispatch(setPackedItemQuantityRoutine(Math.max(1, selectedQuantity - 1)))
  }

  const handleManuallyChangeCounter = e => {
    const re = /[0-9]*/
    const value = e.target.value
    dispatch(
      setPackedItemQuantityRoutine(
        re.test(value) ? Math.max(1, Number(e.target.value)) : value
      )
    )
  }

  const doneCount = orders.filter(
    order => pathOr('', ['status', 'value'], order) === 'packed'
  ).length
  const skippedCount = orders.filter(
    order => pathOr('', ['status', 'value'], order) === 'skipped'
  ).length
  const inProgressCount = orders.length - doneCount - skippedCount

  return (
    <Wrapper>
      <LeftSide>
        <ToVerify>
          <ShoppingBasketOutlinedIcon />
          <div>{inProgressCount}</div>
        </ToVerify>
        <Cancelled>
          <RestartAltOutlinedIcon />
          <div>{skippedCount}</div>
        </Cancelled>
      </LeftSide>
      <Center onSubmit={handleAddManually}>
        <CounterInputWrapper>
          <CounterInput
            type='number'
            onChange={handleManuallyChangeCounter}
            value={selectedQuantity}
          />
          <Controls>
            <div>
              <AddIcon onClick={handleIncrementCounter} />
            </div>
            <div>
              <RemoveIcon onClick={handleDecrementCounter} />
            </div>
          </Controls>
        </CounterInputWrapper>
        <EanInput
          disabled={itemAdded}
          placeholder='EAN'
          onChange={e => {
            dispatch(setPackedItemEanRoutine(e.target.value))
          }}
          value={selectedEan}
        />
        <ButtonWrapper>
          <Button
            type='submit'
            disabled={
              isNilOrEmpty(selectedEan) ||
              isNilOrEmpty(selectedQuantity) ||
              itemAdded
            }
          >
            Dodaj ręcznie
          </Button>
        </ButtonWrapper>
      </Center>
      <RightSide>
        <Verified>
          <CheckCircleIcon />
          <div>{doneCount}</div>
        </Verified>
      </RightSide>
    </Wrapper>
  )
}

export default PackingCurrentStatus

const Wrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 15px;

  div {
    text-align: center;
  }

  input {
    padding: 5px;
  }
`

const ToVerify = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex: 1;
  background-color: #e6b800;
  color: #fff;
  padding: 2px 5px;
`

const Cancelled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex: 1;
  background-color: #000;
  color: #fff;
  padding: 2px 5px;
`

const LeftSide = styled.div`
  flex: 0.196;
  display: flex;
  gap: 10px;
`

const Center = styled.form`
  flex: 0.608;
  display: flex;
  gap: 10px;
`

const RightSide = styled.div`
  flex: 0.196;
  display: flex;
`

const Verified = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex: 1;
  background-color: #00b300;
  padding: 2px 5px;
  color: #fff;
`

const CounterInput = styled.input`
  width: 100px;
`

const CounterInputWrapper = styled.div`
  position: relative;

  input {
    height: 36px;
  }
`

const Controls = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  width: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightGrey};

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex: 1;
  }

  svg {
    font-size: 16px !important;
  }
`

const EanInput = styled.input`
  width: 100%;
  height: 36px;
`

const ButtonWrapper = styled.div`
  button {
    width: 170px;
    height: 36px !important;
  }
`
