import React, { useEffect } from 'react'
import Table from 'components/Table'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import {
  selectCouriersGroupsList, selectCouriersGroupsListPagination,
} from 'modules/couriers/ducks/selectors'
import { fetchCouriersGroupsListRoutine } from 'modules/couriers/ducks/actions'
import AddCouriersGroupModal from 'modules/couriers/components/AddCouriersGroupModal'
import EditCouriersGroupModal from 'modules/couriers/components/EditCouriersGroupModal'
import DeleteCourierGroupModal from 'modules/couriers/components/DeleteCourierGroupModal'
import { ActionsContainer } from 'theme/wrappers'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: 'name'
}

const CouriersGroupsTable = () => {
  const dispatch = useDispatch()
  const list = useSelector(selectCouriersGroupsList)
  const pagination = useSelector(selectCouriersGroupsListPagination)
  const { location: { search } } = useHistory()

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchCouriersGroupsListRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  useEffect(() => {
    changeQuery(defaultFilters)
  }, [])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'Nazwa grupy', sort: 'name' },
    { children: 'Kolor grupy', sort: 'color' },
    { children: '', align: 'right' }
  ]

  const rows = list.map(row => ({
    cells: [
      { children: row.name },
      { children: <Color color={row.color} /> },
      {
        children: (
          <ActionsContainer>
            <EditCouriersGroupModal groupId={row.id} />
            <DeleteCourierGroupModal group={row} />
          </ActionsContainer>
        ),
        align: 'right',
        preventRedirect: true
      }
    ]
  }))

  return (
    <>
      <ButtonWrapper>
        <AddCouriersGroupModal />
      </ButtonWrapper>
      <Table
        pagination={pagination}
        onPageChange={handlePageChange}
        headers={headers}
        rows={rows}
      />
    </>
  )
}

export default CouriersGroupsTable

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`

const Color = styled.div`
  width: 80px;
  height: 20px;
  border-radius: 4px;
  background: ${({ color }) => color || 'transparent'};
`
