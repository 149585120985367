import Table from 'components/Table'
import { fetchStatusLogsRoutine } from 'modules/saleOrders/ducks/actions'
import { selectCurrentSaleOrder } from 'modules/saleOrders/ducks/selectors'
import { pathOr, propOr } from 'ramda'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { changeQuery } from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getStatusColor } from 'utils/saleOrders'

const defaultFilters = {
  page: 1,
  limit: 20
}

const SaleOrderPickerHistory = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const saleOrder = useSelector(selectCurrentSaleOrder)
  const timeline = pathOr([], ['completionList', 'timeline'], saleOrder)

  const {
    location: { search }
  } = useHistory()

  useEffect(() => {
    changeQuery(defaultFilters)
  }, [])

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchStatusLogsRoutine({ id, query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  const headers = [
    { children: 'Typ' },
    { children: 'Status' },
    { children: 'Nr podlisty' },
    { children: 'Opis' },
    { children: 'Użytkownik' },
    { children: 'Czas zakończenia' }
  ]

  const rows = timeline.map(row => {
    return {
      cells: [
        { children: pathOr('---', ['type', 'label'], row) },
        {
          children: (
            <StatusLabel color={getStatusColor(pathOr('---', ['status', 'value'], row) )}>
              {pathOr('---', ['status', 'label'], row) }
            </StatusLabel>
          )
        },
        { children: propOr('---', 'subListNumber', row) },
        { children: propOr('---', 'description', row) },
        { children: propOr('---', 'user', row) },
        { children: propOr('---', 'time', row) }
      ]
    }
  })

  return <Table headers={headers} rows={rows} withStickyHeader />
}

export default SaleOrderPickerHistory

const StatusLabel = styled.div`
  background-color: ${({ color }) => color};
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  margin-right: 10px;
  width: 140px;
`
