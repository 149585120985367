import React, { useEffect, useState } from 'react'
import {
  fetchAllRolesAndStatuesRoutine,
  fetchFlightControlSummaryRoutine
} from 'modules/flightControl/ducks/actions'
import { useDispatch } from 'react-redux'
import PageHeader from 'components/atoms/PageHeader'
import styled from 'styled-components'
import moment from 'moment'
import FlightControlStats from 'modules/flightControl/components/FlightControlStats'
import PackedOrdersStats from 'modules/flightControl/components/PackedOrdersStats'
import FlightControlSaleOrders from 'modules/flightControl/components/FlightControlSaleOrders'
import FlightControlRoleStats from 'modules/flightControl/components/FlightControlRoleStats'
import FlightControlSettings from 'modules/flightControl/components/FilghtControlSettings'

const FlightControl = () => {
  const [currentTime, setCurrentTime] = useState('')
  const [settingsOpen, setSettingsOpen] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFlightControlSummaryRoutine())
    setCurrentTime(moment().format('HH:mm'))
  }, [])

  const handleRefetchData = () => {
    dispatch(fetchAllRolesAndStatuesRoutine())
    setCurrentTime(moment().format('HH:mm'))
  }

  useEffect(() => {
    const interval = setInterval(() => {
      !settingsOpen && handleRefetchData()
    }, 60000)
    return () => clearInterval(interval)
  }, [settingsOpen])

  return (
    <Wrapper>
      <HeaderWrapper>
        <PageHeader>Kontrola lotu</PageHeader>
        <HeaderRightSide>
          <UpdateTime>
            <Label>Godzina aktualizacji:</Label>
            <Value>{currentTime}</Value>
          </UpdateTime>
          <FlightControlSettings
            onRefetch={handleRefetchData}
            settingsOpen={settingsOpen}
            setSettingsOpen={setSettingsOpen}
          />
        </HeaderRightSide>
      </HeaderWrapper>
      <FlightControlStats />
      <PackedOrdersStats />
      <StatsWrapper>
        <FlightControlSaleOrders />
        <FlightControlRoleStats />
      </StatsWrapper>
    </Wrapper>
  )
}

export default FlightControl

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
`

const HeaderRightSide = styled.div`
  display: flex;
  gap: 10px;
`

const UpdateTime = styled.div`
  background: #dc4b19;
  padding: 5px 10px;
  color: #fff;
  text-align: center;
  max-width: 200px;
  border-radius: 6px;
`

const Label = styled.div`
  font-size: 12px;
  text-transform: uppercase;
`

const Value = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const StatsWrapper = styled.div`
  display: flex;
  gap: 50px;
  margin-top: 30px;
`
