import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectStocktakingOrdersList,
  selectStocktakingOrdersPagination
} from 'modules/stocktakingOrders/ducks/selectors'
import { useHistory } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import {
  changeQuery,
  getCurrentParsedQuery,
  isAuthorized
} from 'utils/navigation'
import Table from 'components/Table'
import { fetchStocktakingOrdersRoutine } from 'modules/stocktakingOrders/ducks/actions'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { dropLast, pathOr, propOr } from 'ramda'
import { STOCKTAKING_ORDERS_STATUSES } from 'utils/stocktakingOrders'
import ResetStocktakingOrderAssignmentModal from 'modules/stocktakingOrders/components/ResetStocktakingOrderAssignmentModal '
import CancelStocktakingOrder from 'modules/stocktakingOrders/components/CancelStocktakingOrderModal'
import { ActionsContainer } from 'theme/wrappers'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: '-created_at',
  filter: {
    status: 'pending,assigned'
  }
}

const ActiveStocktakingOrdersTable = () => {
  const list = useSelector(selectStocktakingOrdersList)
  const pagination = useSelector(selectStocktakingOrdersPagination)
  const dispatch = useDispatch()
  const {
    location: { search }
  } = useHistory()
  const currentUser = useSelector(selectCurrentUser)
  const userRoles = propOr([], 'roles', currentUser)
  const priviledgedRoles = ['admin', 'foreman']

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchStocktakingOrdersRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  useEffect(() => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      filter: {
        ...defaultFilters.filter,
        ...propOr({}, 'filter', parsedQuery),
        status: 'pending,assigned'
      }
    })
  }, [])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'Data zlecenia', sort: 'created_at' },
    { children: 'Lokalizacja' },
    { children: 'EAN' },
    { children: 'Nazwa produktu' },
    { children: 'Ilość przed' },
    { children: 'Powód' },
    { children: 'Komentarz' },
    { children: 'Status' },
    { children: 'Osoba zlecająca' },
    { children: 'Data rozpoczęcia' }
  ]

  const rows =
    list &&
    list.map(row => {
      const products = propOr([], 'products', row)
      const allCells = [
        { children: formatDate(row.createdAt, DATE_FORMATS.dashedWithTime) },
        { children: pathOr('', ['location', 'name'], row) },
        {
          children: (
            <>
              {products.map(product => (
                <div key={`ean-${product.id}`}>
                  {pathOr('', ['product', 'ean'], product)}
                </div>
              ))}
            </>
          )
        },
        {
          children: (
            <>
              {products.map(product => (
                <div key={`name-${product.id}`}>
                  {pathOr('', ['product', 'name'], product)}
                </div>
              ))}
            </>
          )
        },
        {
          children: (
            <>
              {products.map(product => (
                <div key={`expected-quantity-${product.id}`}>
                  {pathOr('---', ['expectedQuantity'], product)}
                </div>
              ))}
            </>
          )
        },
        {
          children: pathOr('---', ['source', 'label'], row)
        },
        {
          children: propOr('---', 'comment', row)
        },
        {
          children:
            STOCKTAKING_ORDERS_STATUSES[propOr('undefined', 'status', row)]
        },
        { children: pathOr('---', ['orderedBy', 'username'], row) },
        { children: formatDate(row.assignedAt, DATE_FORMATS.dashedWithTime) },
        {
          children: (
            <ActionsContainer>
              {row.status === 'assigned' && (
                <ResetStocktakingOrderAssignmentModal stocktakingOrder={row} />
              )}
              <CancelStocktakingOrder stocktakingOrder={row} />
            </ActionsContainer>
          ),
          align: 'right',
          preventRedirect: true
        }
      ]
      return {
        cells: isAuthorized(priviledgedRoles, userRoles)
          ? allCells
          : dropLast(1, allCells)
      }
    })

  return (
    <Table
      pagination={pagination}
      onPageChange={handlePageChange}
      headers={
        isAuthorized(priviledgedRoles, userRoles)
          ? headers
          : dropLast(1, headers)
      }
      rows={rows}
      emptyState='Brak zleconych inwentaryzacji'
    />
  )
}

export default ActiveStocktakingOrdersTable
