import {
  fetchSingleStockUnitRoutine,
  fetchStockUnitHistoryRoutine,
  fetchStockUnitKindsRoutine,
  fetchStockUnitsRoutine
} from 'modules/stockUnits/ducks/actions'

const defaultState = {
  list: [],
  pagination: null,
  currentStockUnit: {},
  stockUnitKindList: [],
  stockUnitHistoryList: [],
  stockUnitHistoryListPagination: null
}

const stockUnitsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchStockUnitsRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        pagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    case fetchSingleStockUnitRoutine.SUCCESS:
      return {
        ...state,
        currentStockUnit: action.payload
      }
    case fetchStockUnitHistoryRoutine.SUCCESS:
      return {
        ...state,
        stockUnitHistoryList: action.payload.data,
        stockUnitHistoryListPagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    case fetchStockUnitKindsRoutine.SUCCESS:
      return {
        ...state,
        stockUnitKindList: action.payload.data
      }
    default:
      return state
  }
}

export default stockUnitsReducer
