import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { isEmpty, propOr } from 'ramda'
import { Pagination } from '@mui/material'
import { isNotNilOrEmpty } from 'utils/ramda'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Link, useHistory } from 'react-router-dom'
import Checkbox from 'components/atoms/Checkbox'

const Td = ({ path, children, preventRedirect, ...props }) =>
  isNotNilOrEmpty(path) && !preventRedirect ? (
    <TableCell {...props}>
      <Link to={path}>{children}</Link>
    </TableCell>
  ) : (
    <TableCell {...props}>{children}</TableCell>
  )

const Table = ({
  headers,
  rows,
  emptyState,
  pagination,
  onPageChange,
  onRowClick,
  withCheckboxes,
  onCheck,
  selected,
  hidePerPageSelection
}) => {
  const {
    location: { search }
  } = useHistory()
  const [activeSorting, setActiveSorting] = useState({
    name: '',
    type: 'asc'
  })

  const perPageLimit = useMemo(() => {
    const currentQuery = getCurrentParsedQuery()
    const currentLimit = propOr(20, 'limit', currentQuery)
    return Number(currentLimit)
  }, [search])

  useEffect(() => {
    const currentQuery = getCurrentParsedQuery()
    const currentSorting = propOr('', 'sort', currentQuery)
    const isDescending = currentSorting.includes('-')

    if (isNotNilOrEmpty(currentSorting)) {
      setActiveSorting({
        name: currentSorting.replace('-', ''),
        type: isDescending ? 'desc' : 'asc'
      })
    }
  }, [search])

  const handleRowClick = rowIndex => () => {
    isNotNilOrEmpty(onRowClick) && onRowClick(rowIndex)
  }

  const handleSort = sortBy => () => {
    const currentQuery = getCurrentParsedQuery()
    const currentSorting = propOr('', 'sort', currentQuery)
    const isDescending = currentSorting.includes('-')

    const newQuery = {
      ...currentQuery,
      sort:
        currentSorting.replace('-', '') === sortBy
          ? isDescending
            ? sortBy
            : `-${sortBy}`
          : sortBy
    }

    changeQuery(newQuery)
  }

  const handleRowsPerPageChange = limit => () => {
    const currentQuery = getCurrentParsedQuery()
    changeQuery({
      ...currentQuery,
      page: 1,
      limit
    })
  }

  return (
    <>
      <TableWrapper>
        <thead>
          <HeaderRow>
            {withCheckboxes && <th />}
            {headers.map((header, index) => (
              <HeaderCell key={`table-header-${index}`} align={header.align}>
                <HeaderContent>
                  <div>{header.children}</div>
                  {header.sort && (
                    <SortingIcon
                      onClick={handleSort(header.sort)}
                      active={activeSorting.name === header.sort}
                      isDesc={activeSorting.type === 'desc'}
                    />
                  )}
                </HeaderContent>
              </HeaderCell>
            ))}
          </HeaderRow>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <TableRow
              withPointer={isNotNilOrEmpty(onRowClick)}
              key={`table-row-${rowIndex}`}
              onClick={handleRowClick(rowIndex)}
            >
              {withCheckboxes && (
                <CheckboxCell
                  inactive={row.isInactive}
                  active={row.isActive}
                  key={`table-cell-${rowIndex}-checkbox`}
                  onClick={e => e.stopPropagation()}
                >
                  <Checkbox
                    name={rowIndex}
                    isChecked={selected.some(
                      item => item.id === row.original.id
                    )}
                    onChange={(_, value) => onCheck(row.original, value)}
                    disabled={row.isInactive}
                  />
                </CheckboxCell>
              )}
              {row.cells.map((cell, cellIndex) => {
                return (
                  <Td
                    path={row.redirectPath}
                    inactive={row.isInactive}
                    active={row.isActive}
                    key={`table-cell-${rowIndex}-${cellIndex}`}
                    align={cell.align}
                    preventRedirect={cell.preventRedirect}
                  >
                    <CellChildren>{cell.children}</CellChildren>
                  </Td>
                )
              })}
            </TableRow>
          ))}
        </tbody>
      </TableWrapper>
      <PaginationWrapper>
        {!isEmpty(rows) &&
          isNotNilOrEmpty(pagination) &&
          !hidePerPageSelection && (
            <RowsPerPage>
              <div>Na stronie:</div>
              <RowsPerPageOption
                onClick={handleRowsPerPageChange(20)}
                active={perPageLimit === 20}
              >
                20
              </RowsPerPageOption>
              <RowsPerPageOption
                onClick={handleRowsPerPageChange(50)}
                active={perPageLimit === 50}
              >
                50
              </RowsPerPageOption>
              <RowsPerPageOption
                onClick={handleRowsPerPageChange(100)}
                active={perPageLimit === 100}
              >
                100
              </RowsPerPageOption>
            </RowsPerPage>
          )}
        {isNotNilOrEmpty(pagination) && pagination.totalPages > 1 && (
          <Pagination
            count={pagination.totalPages}
            page={pagination.currentPage}
            onChange={onPageChange}
          />
        )}
      </PaginationWrapper>

      {isEmpty(rows) && <EmptyState>{emptyState}</EmptyState>}
    </>
  )
}

Table.defaultProps = {
  headers: [],
  rows: [],
  emptyState: 'Tabela jest obecnie pusta',
  onPageChange: () => {},
  onRowClick: null,
  onCheck: () => {}
}

export default Table

const TableWrapper = styled.table`
  width: 100%;

  tr {
    &:not(:last-of-type) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGrey};
    }
  }

  td,
  th {
    padding: 5px;
  }

  th {
    vertical-align: bottom;
  }
`

const HeaderRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.main} !important;
  background-color: transparent !important;
`

const HeaderCell = styled.th`
  text-align: ${({ align }) => align || 'left'};
  vertical-align: middle;
  font-size: 14px;
`

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`

const CheckboxCell = styled.th`
  width: 30px;
  text-align: left;
  vertical-align: middle;
  font-size: 14px;
`
const TableCell = styled.td`
  color: ${({ theme, inactive }) => inactive ? theme.colors.disabled : theme.colors.text};  
  text-align: ${({ align }) => align || 'left'};
  font-size: 13px;
  background-color: ${({ theme, active }) => active ? theme.colors.lightGrey : 'inherit'};

  & > * {
    color: ${({ theme, inactive }) => inactive ? theme.colors.disabled : theme.colors.text};

    &:hover {
      color: ${({ theme, inactive }) => inactive && theme.colors.disabled};
    }
  };
`

const CellChildren = styled.div`
  width: 100%;
`

const EmptyState = styled.div`
  height: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PaginationWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
`

const TableRow = styled.tr`
  cursor: ${({ withPointer }) => (withPointer ? 'pointer' : 'default')};
`

const SortingIcon = styled(ArrowDropDownIcon)`
  cursor: pointer;
  color: ${({ theme, active }) =>
    active ? theme.colors.text : theme.colors.disabled};
  transform: rotate(
    ${({ isDesc, active }) => (isDesc && active ? '0deg' : '180deg')}
  );
`

const RowsPerPage = styled.div`
  display: flex;
  gap: 10px;
`

const RowsPerPageOption = styled.div`
  cursor: pointer;
  color: ${({ theme, active }) =>
    active ? theme.colors.primary.main : theme.colors.text};
`
