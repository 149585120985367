import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectCouriers = state => state.couriers

export const selectCouriersList = createSelector(
  selectCouriers,
  propOr([], 'list')
)

export const selectCouriersListPagination = createSelector(
  selectCouriers,
  propOr(
    {
      totalPages: 1,
      perPage: 20,
      itemsCount: 0
    },
    'pagination'
  )
)

export const selectCouriersGroupsList = createSelector(
  selectCouriers,
  propOr([], 'groupsList')
)

export const selectCurrentCouriersGroup = createSelector(
  selectCouriers,
  propOr([], 'currentGroup')
)

export const selectCouriersGroupsListPagination = createSelector(
  selectCouriers,
  propOr(
    {
      totalPages: 1,
      perPage: 20,
      itemsCount: 0
    },
    'groupsPagination'
  )
)

export const selectUngroupedCouriersList = createSelector(
  selectCouriers,
  propOr([], 'ungroupedList')
)
