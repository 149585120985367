import React, { useEffect, useMemo } from 'react'
import { selectPickerStats } from 'modules/flightControl/ducks/selectors'
import { fetchFlightControlStatsByRoleRoutine } from 'modules/flightControl/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Table from 'components/Table'
import { isNotNilOrEmpty } from 'utils/ramda'
import { keys } from 'ramda'
import CircleIcon from '@mui/icons-material/Circle'

const PickerStatsTable = () => {
  const stats = useSelector(selectPickerStats)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFlightControlStatsByRoleRoutine({ role: 'picker' }))
  }, [])

  const users = useMemo(() => {
    if (isNotNilOrEmpty(stats)) {
      const logins = keys(stats)
      return logins.map(login => stats[login])
    } else {
      return []
    }
  }, [stats])

  const headers = [
    { children: 'Login' },
    { children: 'Czas logowania' },
    { children: 'Zadania' },
    { children: 'Zebrane towary' },
    { children: 'Aktywny' }
  ]

  const rows = users.map(user => ({
    cells: [
      { children: user.login },
      { children: user.loginTime },
      { children: user.picksNumber },
      { children: user.productsNumber },
      { children: (
        <BulletWrapper>
          <BulletIcon active={user.isActive} />
        </BulletWrapper>
      ) }
    ]
  }))

  return (
    <Wrapper>
      <Header>Statystyki Pickera</Header>
      <Table
        rows={rows}
        headers={headers}
      />
    </Wrapper>
  )
}

export default PickerStatsTable

const Wrapper = styled.div`
  max-width: 450px;
  min-width: 450px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f0f3f4;
`

const Header = styled.div`
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 18px;
`

const BulletWrapper = styled.div`
  text-align: center;
`

const BulletIcon = styled(CircleIcon)`
  font-size: 12px !important;
  color: ${({ theme, active }) => active ? theme.colors.success : theme.colors.error};
`
