import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectLocations = state => state.locations

export const selectLocationsList = createSelector(
  selectLocations,
  propOr([], 'list')
)

export const selectCurrentLocation = createSelector(
  selectLocations,
  propOr({}, 'currentLocation')
)

export const selectLocationsListPagination = createSelector(
  selectLocations,
  propOr({
    totalPages: 1,
    perPage: 20,
    itemsCount: 0
  }, 'pagination')
)

export const selectLocationHistoryList = createSelector(
  selectLocations,
  propOr({}, 'locationHistoryList')
)

export const selectLocationHistoryListPagination = createSelector(
  selectLocations,
  propOr({
    totalPages: 1,
    perPage: 20,
    itemsCount: 0
  }, 'locationHistoryListPagination')
)
