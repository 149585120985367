import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import {
  changeQuery,
  getCurrentParsedQuery,
  parseQuery,
  stringifyQuery
} from 'utils/navigation'
import Table from 'components/Table'
import { propOr } from 'ramda'
import styled from 'styled-components'
import { getUserRoleLogsRoutine } from 'modules/users/ducks/actions'
import { selectUsersLogs } from 'modules/users/ducks/selectors'
import { fetchUserRolesDictionaryRoutine } from 'ducks/dictionaries/actions'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/date'
import ContentPanel from 'components/atoms/ContentPanel'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: 'username',
  filter: {
    created_after: moment().format(DATE_FORMATS.input),
    created_before: moment().format(DATE_FORMATS.input),
    role: 'packer'
  }
}

const PackerStatisticsTable = () => {
  const list = useSelector(selectUsersLogs)
  const dispatch = useDispatch()
  const {
    location: { search }
  } = useHistory()

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      const parsedQuery = parseQuery(search)
      const query = {
        ...parsedQuery,
        filter: { ...parsedQuery.filter, role: 'packer' }
      }
      dispatch(getUserRoleLogsRoutine({ query: stringifyQuery(query) }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  useEffect(() => {
    const parsedQuery = getCurrentParsedQuery()
    dispatch(fetchUserRolesDictionaryRoutine())
    changeQuery({
      ...parsedQuery,
      filter: {
        ...defaultFilters.filter,
        ...propOr({}, 'filter', parsedQuery)
      }
    })
  }, [])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'Operator' },
    { children: 'Ilość wszystkich paczek' },
    { children: 'Ilość paczek 1 SKU' },
    { children: 'Ilość paczek MULTI SKU' },
    { children: 'Ilość wszystkich towarów' },
    { children: 'Ilość towarów 1 SKU' },
    { children: 'Ilość towarów MULTI SKU' }
  ]

  const rows =
    list &&
    list.map(row => ({
      cells: [
        { children: propOr('---', 'username', row) },
        {
          children:
            propOr(0, 'inspectedPackagesSkuNumber', row) +
            propOr(0, 'inspectedPackagesMultiSkuNumber', row)
        },
        { children: propOr(0, 'inspectedPackagesSkuNumber', row) },
        { children: propOr(0, 'inspectedPackagesMultiSkuNumber', row) },
        { children: propOr(0, 'productsNumber', row) },
        { children: propOr(0, 'productsSkuNumber', row) },
        { children: propOr(0, 'productsMultiSkuNumber', row) }
      ]
    }))

  return (
    <ContentPanel>
      <TableWrapper>
        <Table
          onPageChange={handlePageChange}
          headers={headers}
          rows={rows}
          emptyState='Brak statystyk'
        />
      </TableWrapper>
    </ContentPanel>
  )
}

export default PackerStatisticsTable

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`
