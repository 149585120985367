import React, { useEffect } from 'react'
import Table from 'components/Table'
import { useDispatch, useSelector } from 'react-redux'
import { propOr } from 'ramda'
import { fetchStockUnitKindsRoutine } from 'modules/stockUnits/ducks/actions'
import { selectStockUnitKindList } from 'modules/stockUnits/ducks/selectors'
import { DATE_FORMATS, formatDate } from 'utils/date'

const StockUnitKindsTable = () => {
  const dispatch = useDispatch()
  const list = useSelector(selectStockUnitKindList)

  useEffect(() => {
    dispatch(fetchStockUnitKindsRoutine())
  }, [])

  const headers = [
    { children: 'Nazwa' },
    { children: 'Limit objętości' },
    { children: 'Szerokość [cm]' },
    { children: 'Wysokość [cm]' },
    { children: 'Głębokość [cm]' },
    { children: 'Data utworzenia' }
  ]

  const rows = list.map(row => ({
    cells: [
      { children: propOr('', 'name', row) },
      { children: propOr(false, 'limitVolume', row) ? '✓' : '✕' },
      { children: propOr('', 'width', row) },
      { children: propOr('', 'height', row) },
      { children: propOr('', 'depth', row) },
      {
        children: formatDate(
          propOr(null, 'createdAt', row),
          DATE_FORMATS.dashedWithTime
        )
      }
    ]
  }))

  return (
    <>
      <Table
        headers={headers}
        rows={rows}
        emptyState='Brak rodzajów jednostek magazynowych'
      />
    </>
  )
}

export default StockUnitKindsTable
