import React, { useEffect } from 'react'
import Table from 'components/Table'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { fetchUsersListRoutine } from 'modules/users/ducks/actions'
import {
  selectUsersList,
  selectUsersListPagination
} from 'modules/users/ducks/selectors'
import { useHistory } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { propOr } from 'ramda'
import EditUserModal from 'modules/users/components/EditUserModal'
import { fetchUserRolesDictionaryRoutine } from 'ducks/dictionaries/actions'
import ResetUserPasswordModal from 'modules/users/components/ResetUserPasswordModal'
import { selectUserRolesDictionary } from 'ducks/dictionaries/selectors'
import { ActionsContainer } from 'theme/wrappers'
import { toCamelCase } from 'js-convert-case'

const defaultFilters = {
  page: 1,
  limit: 20
}

const UsersTable = () => {
  const dispatch = useDispatch()
  const list = useSelector(selectUsersList)
  const pagination = useSelector(selectUsersListPagination)
  const userRolesDictionary = useSelector(selectUserRolesDictionary)
  const {
    location: { search }
  } = useHistory()

  useEffect(() => {
    dispatch(fetchUserRolesDictionaryRoutine())
  }, [])

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchUsersListRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'Login użytkownika' },
    { children: 'Rola' },
    { children: 'Aktywny' },
    { children: '', align: 'right' }
  ]

  const rows = list.map(row => {
    return {
      cells: [
        { children: row.username },
        {
          children: (
            <RolesWrapper>
              {propOr([], 'roles', row)
                .map(role => userRolesDictionary[toCamelCase(role)])
                .join(', ')}
            </RolesWrapper>
          )
        },
        { children: row.isActive ? '✓' : '✗' },
        {
          children: (
            <ActionsContainer>
              <ResetUserPasswordModal user={row} />
              <EditUserModal user={row} />
            </ActionsContainer>
          ),
          align: 'right',
          preventRedirect: true
        }
      ]
    }
  })

  return (
    <Table
      pagination={pagination}
      onPageChange={handlePageChange}
      headers={headers}
      rows={rows}
    />
  )
}

export default UsersTable

const RolesWrapper = styled.div``
