import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectInventoryMoveLogs = state => state.moveLogs

export const selectInventoryMoveLogsList = createSelector(
  selectInventoryMoveLogs,
  propOr([], 'list')
)

export const selectInventoryMoveLogsListPagination = createSelector(
  selectInventoryMoveLogs,
  propOr(
    {
      totalPages: 1,
      perPage: 20,
      itemsCount: 0
    },
    'pagination'
  )
)
