import React, { useEffect } from 'react'
import Table from 'components/Table'
import { pathOr, propOr } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectInventoryMoveLogsList,
  selectInventoryMoveLogsListPagination
} from '../ducks/selectors'
import { useHistory } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import { fetchInventoryMoveLogsRoutine } from '../ducks/actions'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { DATE_FORMATS, formatDate } from 'utils/date'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: '-created_at'
}

const InventoryMoveLogsTable = () => {
  const dispatch = useDispatch()
  const list = useSelector(selectInventoryMoveLogsList)
  const pagination = useSelector(selectInventoryMoveLogsListPagination)

  const {
    location: { search }
  } = useHistory()

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchInventoryMoveLogsRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'Data', sort: 'created_at' },
    { children: 'Status' },
    { children: 'Typ', sort: 'type' },
    { children: 'Lokalizacja źródłowa', sort: 'location_from' },
    { children: 'Lokalizacja docelowa', sort: 'location_to' },
    { children: 'EAN', sort: 'product' },
    { children: 'Nazwa', sort: 'product' },
    { children: 'Ilość' }
  ]

  const rows = list.map(row => {
    const subiektLogs = propOr([], 'subiektLogs', row)
    return {
      cells: [
        {
          children: formatDate(
            propOr('---', 'createdAt', row),
            DATE_FORMATS.dashedWithTime
          )
        },
        {
          children: isNotNilOrEmpty(subiektLogs) ? subiektLogs.map(log => (
            <div key={log.id}>{pathOr('---', ['status', 'label'], log)}</div>
          )) : <div>---</div>
        },
        { children: pathOr('---', ['type', 'label'], row) },
        { children: pathOr('---', ['locationFrom', 'name'], row) },
        { children: pathOr('---', ['locationTo', 'name'], row) },
        { children: pathOr('---', ['product', 'ean'], row) },
        { children: pathOr('---', ['product', 'name'], row) },
        { children: propOr('---', 'quantity', row) }
      ]
    }
  })

  return (
    <Table
      headers={headers}
      rows={rows}
      pagination={pagination}
      onPageChange={handlePageChange}
    />
  )
}

export default InventoryMoveLogsTable
