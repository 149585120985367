import {
  fetchFlightControlSaleOrdersByStatusRoutine,
  fetchFlightControlSaleOrdersRoutine,
  fetchFlightControlSettingsRoutine,
  fetchFlightControlStatsByRoleRoutine,
  fetchFlightControlSummaryRoutine
} from 'modules/flightControl/ducks/actions'

const defaultState = {
  summary: {},
  saleOrders: []
}

const flightControlReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchFlightControlSummaryRoutine.SUCCESS:
      return {
        ...state,
        summary: action.payload
      }
    case fetchFlightControlSaleOrdersRoutine.SUCCESS:
      return {
        ...state,
        saleOrders: action.payload
      }
    case fetchFlightControlSaleOrdersByStatusRoutine.SUCCESS:
      return {
        ...state,
        [action.payload.status]: action.payload.data
      }
    case fetchFlightControlStatsByRoleRoutine.SUCCESS:
      return {
        ...state,
        [action.payload.role]: action.payload.data
      }
    case fetchFlightControlSettingsRoutine.SUCCESS:
      return {
        ...state,
        settings: action.payload
      }
    default:
      return state
  }
}

export default flightControlReducer
