import React, { useMemo } from 'react'
import UpdateDeliveryForm from 'modules/deliveryDetails/components/UpdateDeliveryForm'
import { useSelector } from 'react-redux'
import { selectCurrentDelivery } from 'modules/delivery/ducks/selectors'
import styled from 'styled-components'
import { pathOr, propOr } from 'ramda'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { DELIVERY_STATUSES, DELIVERY_STATUS_COLORS } from 'utils/delivery'
import { isNotNilOrEmpty } from 'utils/ramda'

const DeliveryDetailsBasicTab = () => {
  const delivery = useSelector(selectCurrentDelivery)
  const unloaders = propOr([], 'unloaders', delivery)
  const statusColor = useMemo(() => {
    return DELIVERY_STATUS_COLORS[delivery.status]
  }, [delivery])

  return (
    <>
      <BasicDetails>
        <DetailItem>
          <div>Status</div>
          <StatusTag color={statusColor}>{DELIVERY_STATUSES[pathOr('---', ['status'], delivery)]}</StatusTag>
        </DetailItem>
        <DetailItem>
          <div>ID</div>
          <div>{pathOr('---', ['identifier'], delivery)}</div>
        </DetailItem>
        <DetailItem>
          <div>Dodana przez</div>
          <div>{pathOr('---', ['createdBy', 'username'], delivery)}</div>
        </DetailItem>
        <DetailItem>
          <div>Data dodania</div>
          <div>{formatDate(pathOr(null, ['createdAt'], delivery), DATE_FORMATS.dashedWithTime)}</div>
        </DetailItem>
        <DetailItem>
          <div>Przyjmujący</div>
          {isNotNilOrEmpty(unloaders) ? (
            unloaders.map(item => (
              <div key={item.id}>{propOr('---', 'username', item)}</div>
            ))
          ) : (
            <div>---</div>
          )}
        </DetailItem>
      </BasicDetails>
      <UpdateDeliveryForm />
    </>
  )
}

export default DeliveryDetailsBasicTab

const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`

const BasicDetails = styled.div`
  margin-bottom: 30px;
`

const StatusTag = styled.div`
  padding: 3px 10px;
  background-color: ${({ color }) => color};
  color: #fff;
  border-radius: 4px;
  
`
