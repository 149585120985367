import {
  clearSelectedMultipickDatesRoutine,
  clearSelectedMultipickValuesRoutine,
  clearSimulatedMultipicksRoutine,
  currentGeneratedOrdersRoutine,
  fetchGeneratedMultipickListsRoutine,
  fetchMultipickDictionaryRoutine,
  fetchMultipickRulesetsListRoutine,
  saveSelectedMultipickDatesRoutine,
  saveSelectedMultipickValuesRoutine,
  simulateMultipicksRoutine
} from 'modules/multipick/ducks/actions'
import moment from 'moment'

const defaultState = {
  isDictionaryFetching: false,
  list: [],
  dictionary: {},
  generatedLists: [],
  simulatedLists: [],
  selectedValues: {
    name: '',
    orderType: '',
    allowStandard: false,
    allowCumbersome: false,
    allowHeavy: false,
    minItems: 1,
    maxItems: 50,
    minUniqueProducts: 1,
    maxUniqueProducts: 50,
    priority: 5,
    courierGroupId: '',
    packagingTypes: [],
    sources: [],
    stockUnitKinds: [],
    limitBy: 'items_count',
    minStockUnitFilling: 0,
    maxStockUnitFilling: 80
  },
  selectedDates: {
    ordered_after: moment().subtract(1, 'days').startOf('day').toDate(),
    ordered_before: moment().toDate()
  },
  currentGeneratedOrders: []
}

const multipickReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchMultipickRulesetsListRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    case fetchMultipickDictionaryRoutine.REQUEST:
      return {
        ...state,
        isDictionaryFetching: true
      }
    case fetchMultipickDictionaryRoutine.SUCCESS:
      return {
        ...state,
        dictionary: action.payload,
        isDictionaryFetching: false
      }
    case fetchMultipickDictionaryRoutine.FAILURE:
      return {
        ...state,
        isDictionaryFetching: false
      }
    case simulateMultipicksRoutine.SUCCESS:
      return {
        ...state,
        simulatedLists: action.payload
      }
    case clearSimulatedMultipicksRoutine.SUCCESS:
      return {
        ...state,
        simulatedLists: defaultState.simulatedLists
      }
    case fetchGeneratedMultipickListsRoutine.SUCCESS:
      return {
        ...state,
        generatedLists: action.payload.data
      }
    case saveSelectedMultipickValuesRoutine.SUCCESS:
      return {
        ...state,
        selectedValues: action.payload
      }
    case clearSelectedMultipickValuesRoutine.SUCCESS:
      return {
        ...state,
        selectedValues: defaultState.selectedValues
      }
    case saveSelectedMultipickDatesRoutine.SUCCESS:
      return {
        ...state,
        selectedDates: action.payload
      }
    case clearSelectedMultipickDatesRoutine.SUCCESS:
      return {
        ...state,
        selectedDates: defaultState.selectedDates
      }
    case currentGeneratedOrdersRoutine.SUCCESS:
      return {
        ...state,
        currentGeneratedOrders: action.payload
      }
    default:
      return state
  }
}

export default multipickReducer
