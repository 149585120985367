import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectPackingTypes = state => state.packingTypes

export const selectPackingTypesList = createSelector(
  selectPackingTypes,
  propOr([], 'list')
)

export const selectPackingTypesListPagination = createSelector(
  selectPackingTypes,
  propOr({
    totalPages: 1,
    perPage: 20,
    itemsCount: 0
  }, 'pagination')
)
