import React from 'react'
import styled from 'styled-components'

const ContentPanel = ({ children, withMargin }) => {
  return (
    <Wrapper withMargin={withMargin}>
      {children}
    </Wrapper>
  )
}

export default ContentPanel

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.main};
  width: 100%;
  padding: 20px;
  margin-bottom: ${({ withMargin }) => withMargin ? '20px' : 0};
`
