import React, { useEffect, useState } from 'react'
import Table from 'components/Table'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { changeQuery, getCurrentParsedQuery, isAuthorized } from 'utils/navigation'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { dropLast, pathOr, propOr } from 'ramda'
import { fetchStockUnitsRoutine } from 'modules/stockUnits/ducks/actions'
import {
  selectStockUnitsList,
  selectStockUnitsPagination
} from 'modules/stockUnits/ducks/selectors'
import { STOCK_UNIT_TYPES } from 'utils/stockUnit'
import DeactivateStockUnitModal from 'modules/stockUnits/components/DeactivateStockUnitModal'
import { DATE_FORMATS, formatDate } from 'utils/date'
import Button from 'components/atoms/Button'
import { getCsvCodes } from 'services/StockUnitsService'
import { toast } from 'react-hot-toast'
import { getApiErrors } from 'utils/errors'
import { downloadCSV } from 'utils/files'
import { ActionsContainer } from 'theme/wrappers'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: 'prefix'
}

const StockUnitsTable = () => {
  const dispatch = useDispatch()
  const list = useSelector(selectStockUnitsList)
  const pagination = useSelector(selectStockUnitsPagination)
  const {
    location: { search }
  } = useHistory()
  const [selected, setSelected] = useState([])
  const currentUser = useSelector(selectCurrentUser)
  const userRoles = propOr([], 'roles', currentUser)
  const priviledgedRolesDeactivate = ['admin']
  const priviledgedRolesExportCodes = ['admin', 'foreman', 'bok']

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchStockUnitsRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const toggleCheckbox = (row, value) => {
    value
      ? setSelected(prev => [...prev, row])
      : setSelected(prev => prev.filter(o => o.id !== row.id))
  }

  const headers = [
    { children: 'Prefix', sort: 'prefix' },
    { children: 'Kod', sort: 'code' },
    { children: 'Rodzaj', sort: 'kind' },
    { children: 'Typ', sort: 'type' },
    { children: 'Magazyn', sort: 'warehouse' },
    { children: 'Strefa', sort: 'zone' },
    { children: 'Lokalizacja', sort: 'location' },
    { children: 'Ilość towaru' },
    { children: 'Data pierwszego użycia' },
    { children: 'Aktywna' },
    { children: '', align: 'right' }
  ]

  const rows = list.map(row => {
    const canDeactivate =
    isNilOrEmpty(row.deactivatedAt) &&
    propOr(0, 'productsQuantity', row) === 0

    const allCells =  [
      { children: propOr('---', 'prefix', row).toUpperCase() },
      { children: propOr('---', 'code', row) },
      { children: pathOr('---', ['kind', 'name'], row) },
      { children: STOCK_UNIT_TYPES[propOr('undefined', 'type', row)] },
      { children: pathOr('---', ['location', 'warehouse', 'name'], row) },
      { children: pathOr('---', ['location', 'zone', 'name'], row) },
      { children: pathOr('---', ['location', 'name'], row) },
      { children: propOr('---', 'productsQuantity', row) },
      {
        children: formatDate(
          propOr(null, 'firstUsedAt', row),
          DATE_FORMATS.dashedWithTime
        )
      },
      { children: propOr(null, 'deactivatedAt', row) ? '✗' : '✔' },
      {
        children: (
          <ActionsContainer>
            {canDeactivate && (
              <DeactivateStockUnitModal stockUnit={row} />
            )}
          </ActionsContainer>
        ),
        align: 'right',
        preventRedirect: true
      }
    ]
    return {
      original: row,
      isInactive: isNotNilOrEmpty(row.deactivatedAt),
      redirectPath: `/stock-units/${row.id}`,
      cells: isAuthorized(priviledgedRolesDeactivate, userRoles) ? allCells : dropLast(1, allCells)
    }
  })

  const handleCsvDownload = () => {
    getCsvCodes({ codes: selected.map(item => item.code) })
      .then(resp => {
        downloadCSV(
          resp.data,
          `kody_jednostek-${new Date().toLocaleDateString()}`
        )
      })
      .catch(error => {
        toast.error(getApiErrors(error))
      })
  }

  return (
    <>
      {selected.length > 0 && (
        <SelectedWrapper>
          <div>Zaznaczene jednostki: {selected.length}</div>
          <Button small onClick={handleCsvDownload} color='primary-outline'>
            Pobierz kody
          </Button>
        </SelectedWrapper>
      )}
      <Table
        withCheckboxes={isAuthorized(priviledgedRolesExportCodes, userRoles)}
        onCheck={toggleCheckbox}
        selected={selected}
        pagination={pagination}
        onPageChange={handlePageChange}
        headers={isAuthorized(priviledgedRolesDeactivate, userRoles) ? headers : dropLast(1, headers)}
        rows={rows}
        emptyState='Brak jednostek magazynowych do wyświetlenia'
      />
    </>
  )
}

export default StockUnitsTable

const SelectedWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 15px;

  button {
    max-width: 150px;
  }
`
