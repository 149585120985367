import React, { useEffect } from 'react'
import Table from 'components/Table'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectPackingTypesList,
  selectPackingTypesListPagination
} from 'modules/packingTypes/ducks/selectors'
import { fetchPackingTypesRoutine } from 'modules/packingTypes/ducks/actions'
import EditPackingTypeModal from 'modules/packingTypes/components/EditPackingTypeModal'
import RemovePackingTypeModal from 'modules/packingTypes/components/RemovePackingTypeModal'
import { useHistory } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import {
  changeQuery,
  getCurrentParsedQuery,
  isAuthorized,
  redirectWithQuery
} from 'utils/navigation'
import { ActionsContainer } from 'theme/wrappers'
import { dropLast, propOr } from 'ramda'
import styled from 'styled-components'
import { Tooltip } from '@mui/material'
import PATHS from 'utils/paths'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'

const defaultFilters = {
  page: 1,
  limit: 20
}

const PackingTypesTable = () => {
  const dispatch = useDispatch()
  const list = useSelector(selectPackingTypesList)
  const pagination = useSelector(selectPackingTypesListPagination)
  const {
    location: { search }
  } = useHistory()
  const currentUser = useSelector(selectCurrentUser)
  const userRoles = propOr([], 'roles', currentUser)
  const priviledgedRoles = ['admin']

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchPackingTypesRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const handleProductsList = id => e => {
    e && e.stopPropagation()
    const query = {
      page: 1,
      limit: 100,
      sort: 'name',
      filter: { packaging_type: id }
    }
    redirectWithQuery(PATHS.products, query)
  }

  const headers = [
    { children: 'Typ opakowania' },
    { children: 'Ilość produktów' },
    { children: '', align: 'right' }
  ]

  const rows = list.map(row => {
    const id = propOr('---', 'id', row)
    const allCells = [
      { children: propOr('---', 'name', row) },
      {
        children: (
          <Tooltip title='Produkty z wybranym opakowaniem'>
            <ProductCount onClick={handleProductsList(id)}>
              {propOr('---', 'productsCount', row)}
            </ProductCount>
          </Tooltip>
        )
      },
      {
        children: (
         <ActionsContainer>
            <EditPackingTypeModal type={row} />
            <RemovePackingTypeModal type={row} />
          </ActionsContainer>
        ),
        align: 'right',
        preventRedirect: true
      }
    ]

    return {
      cells: isAuthorized(priviledgedRoles, userRoles) ? allCells : dropLast(1, allCells)
    }
  })

  return (
    <Table
      pagination={pagination}
      onPageChange={handlePageChange}
      headers={isAuthorized(priviledgedRoles, userRoles) ? headers : dropLast(1, headers)}
      rows={rows}
    />
  )
}

export default PackingTypesTable

const ProductCount = styled.div`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`
