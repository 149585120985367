import React, { useMemo } from 'react'
import styled from 'styled-components'
import { getPackingColor } from 'utils/packing'
import { pathOr, propOr } from 'ramda'
import { setCurrentPackingOrderRoutine } from 'modules/packing/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentPackingOrder } from '../ducks/selectors'
import { toast } from 'react-hot-toast'
import { SALE_ORDERS_STATUSES } from 'utils/saleOrders'

const PackingOrderBox = ({ order }) => {
  const status = pathOr('', ['status', 'value'], order)
  const id = propOr('', 'id', order)
  const currentOrder = useSelector(selectCurrentPackingOrder)
  const currentOrderStatus = pathOr('', ['status', 'value'], currentOrder)
  const currentOrderId = propOr('', 'id', currentOrder)
  const dispatch = useDispatch()

  const color = useMemo(() => {
    return getPackingColor(status)
  }, [status])

  const completionListName = pathOr('', ['completionList', 'name'], order)

  const handleCurrentOrder = () => {
    if (id === currentOrderId) return
    currentOrderStatus === 'partially_packed'
      ? toast.error(
          'Nie ukończyłeś rozpoczętego zamówienia. Spakuj wszystko lub pomiń zamówienie.'
        )
      : dispatch(
          setCurrentPackingOrderRoutine({
            ...order,
            previewMode: true
          })
        )
  }

  return (
    <Box color={color} onClick={handleCurrentOrder}>
      <BoxTitle color={color}>
        Zam. {SALE_ORDERS_STATUSES[status]}{' '}
        {order.iaiOrderSerialNumber}
      </BoxTitle>
      <BoxContent status={status}>{completionListName}</BoxContent>
    </Box>
  )
}

export default PackingOrderBox

const Box = styled.div`
  border: 1px solid ${({ color }) => color};
  margin-bottom: 10px;
  cursor: pointer;
`

const BoxTitle = styled.div`
  background-color: ${({ color }) => color};
  color: #fff;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  font-size: 13px;
`

const BoxContent = styled.div`
  display: flex;
  align-items: center;
  line-height: 14px;
  text-decoration: ${({ status }) =>
    status === 'cancelled' ? 'line-through' : 'none'};
  padding: 5px;
  font-size: 14px;
`
