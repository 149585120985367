import React, { useEffect } from 'react'
import { selectImportJobsList, selectImportJobsListPagination } from 'modules/importJobsReport/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { pathOr, propOr } from 'ramda'
import { DATE_FORMATS, formatDate } from 'utils/date'
import Table from 'components/Table'
import { fetchImportJobsRoutine } from 'modules/importJobsReport/ducks/actions'
import { IMPORT_TYPES } from 'utils/importTypes'
import { isNotNilOrEmpty } from 'utils/ramda'
import { changeQuery, getCurrentParsedQuery } from 'utils/navigation'
import { useHistory } from 'react-router-dom'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: 'created_at'
}

const FinishedJobImportsTab = () => {
  const jobs = useSelector(selectImportJobsList)
  const pagination = useSelector(selectImportJobsListPagination)
  const dispatch = useDispatch()
  const { location: { search } } = useHistory()

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchImportJobsRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  const headers = [
    { children: 'Data', sort: 'created_at' },
    { children: 'Typ' },
    { children: 'Wynik' },
    { children: 'Zaimportowanych' }
  ]

  const rows = jobs.map(row => ({
    cells: [
      { children: formatDate(propOr('', 'createdAt', row), DATE_FORMATS.dashedWithTime) },
      { children: IMPORT_TYPES[propOr('undefined', 'type', row)] },
      { children: propOr(false, 'isSuccessful', row) ? '✓' : '✕' },
      { children: pathOr(0, ['result', 'imported'], row) },
    ]
  }))

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  return (
    <Table
      rows={rows}
      headers={headers}
      pagination={pagination}
      onPageChange={handlePageChange}
    />
  )
}

export default FinishedJobImportsTab
