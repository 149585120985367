import Table from 'components/Table'
import { indexOf, pathOr, propOr } from 'ramda'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { getMultipickListDetails } from 'services/MultipickService'
import styled from 'styled-components'
import { getApiErrors } from 'utils/errors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getStatusColor } from 'utils/saleOrders'

const SaleOrderCompletionList = ({ id }) => {
  const [currentList, setCurrentList] = useState([])
  const saleOrdersList = propOr([], 'orders', currentList)

  useEffect(() => {
    isNotNilOrEmpty(id) &&
      getMultipickListDetails({ id })
        .then(resp => {
          setCurrentList(resp.data.data)
        })
        .catch(err => {
          setCurrentList({})
          toast.error(getApiErrors(err))
        })
  }, [])

  const headers = [
    { children: 'Nr' },
    { children: 'Nr zamówienia' },
    { children: 'Status' }
  ]

  const rows = saleOrdersList.map(row => {
    const status = pathOr('---', ['status', 'label'], row)
    const statusValue = pathOr('---', ['status', 'value'], row)
    return {
      cells: [
        {
          children: indexOf(row, saleOrdersList) + 1
        },
        { children: propOr('---', 'iaiOrderSerialNumber', row) },
        {
          children: (
            <StatusLabel color={getStatusColor(statusValue)}>
              {status}
            </StatusLabel>
          )
        }
      ]
    }
  })

  return <Table headers={headers} rows={rows} withStickyHeader />
}

export default SaleOrderCompletionList

const StatusLabel = styled.div`
  background-color: ${({ color }) => color};
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  margin-right: 10px;
  width: 140px;
`
