import {
  fetchUsersByRoleListRoutine,
  fetchUsersListRoutine,
  getUserRoleLogsRoutine
} from 'modules/users/ducks/actions'

const defaultState = {
  list: [],
  listByRoles: {},
  pagination: null,
  logs: []
}

const usersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchUsersListRoutine.SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        pagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    case fetchUsersByRoleListRoutine.SUCCESS:
      return {
        ...state,
        listByRoles: action.payload
      }
    case getUserRoleLogsRoutine.SUCCESS:
      return {
        ...state,
        logs: action.payload.data
      }
    default:
      return state
  }
}

export default usersReducer
