import React, { useEffect } from 'react'
import Table from 'components/Table'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProductsRoutine } from 'modules/products/ducks/actions'
import {
  selectProductsList,
  selectProductsListPagination
} from 'modules/products/ducks/selectors'
import { useHistory } from 'react-router-dom'
import {
  changeQuery,
  getCurrentParsedQuery,
  isAuthorized
} from 'utils/navigation'
import { isNotNilOrEmpty } from 'utils/ramda'
import { dropLast, pathOr, propOr } from 'ramda'
import { INPOST_SIZE } from 'utils/product'
import LocalizationsTooltip from 'modules/products/components/LocalizationsTooltip'
import { Tooltip } from '@mui/material'
import Barcode from 'react-barcode'
import EditProductModal from './EditProductModal'
import CreateStocktakingOrderModal from 'modules/locations/components/CreateStocktakingOrderModal'
import { ActionsContainer } from 'theme/wrappers'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import RemoveProductModal from './RemoveProductModal'

const defaultFilters = {
  page: 1,
  limit: 20,
  sort: 'name'
}

const ProductsTable = () => {
  const dispatch = useDispatch()
  const list = useSelector(selectProductsList)
  const pagination = useSelector(selectProductsListPagination)
  const {
    location: { search }
  } = useHistory()
  const currentUser = useSelector(selectCurrentUser)
  const userRoles = propOr([], 'roles', currentUser)
  const priviledgedRolesActions = [
    'admin',
    'foreman',
    'bok',
    'warehouseman',
    'stocktaker',
    'outbounds'
  ]
  const priviledgedRolesUpdateProduct = [
    'admin',
    'foreman',
    'bok',
    'warehouseman'
  ]
  const priviledgedRolesRemoveProduct = ['admin']

  useEffect(() => {
    if (isNotNilOrEmpty(search)) {
      dispatch(fetchProductsRoutine({ query: search }))
    } else {
      changeQuery(defaultFilters)
    }
  }, [search])

  const handlePageChange = (_, page) => {
    const parsedQuery = getCurrentParsedQuery()
    changeQuery({
      ...parsedQuery,
      page
    })
  }

  const headers = [
    { children: 'EAN' },
    { children: 'Nazwa', sort: 'name' },
    { children: 'Ilość', sort: 'total_quantity' },
    { children: 'Rodzaj opakowania' },
    { children: 'Wymiar inpost' },
    { children: '', align: 'right' }
  ]

  const rows = list.map(row => {
    const allCells = [
      {
        children: (
          <Tooltip title={<Barcode value={propOr('---', 'ean', row)} />}>
            <div>{propOr('---', 'ean', row)}</div>
          </Tooltip>
        )
      },
      { children: propOr('---', 'name', row) },
      {
        children: (
          <LocalizationsTooltip
            totalQuantity={propOr('---', 'totalQuantity', row)}
            locations={propOr([], 'inventory', row).map(location => ({
              name: pathOr('', ['inventorable', 'name'], location),
              quantity: pathOr('', ['quantity'], location),
              id: pathOr('', ['inventorable', 'id'], location)
            }))}
          />
        )
      },
      { children: pathOr('---', ['packagingType', 'name'], row) },
      {
        children: propOr('', 'inpostSize', row)
          ? INPOST_SIZE[row.inpostSize]
          : '---'
      },
      {
        children: (
          <ActionsContainer>
            {isAuthorized(priviledgedRolesRemoveProduct, userRoles) ? (
              <RemoveProductModal product={row} />
            ) : null}
            <CreateStocktakingOrderModal product={row} />
            {isAuthorized(priviledgedRolesUpdateProduct, userRoles) ? (
              <EditProductModal product={row} />
            ) : null}
          </ActionsContainer>
        ),
        align: 'right',
        preventRedirect: true
      }
    ]

    return {
      redirectPath: `/products/${row.id}`,
      cells: isAuthorized(priviledgedRolesActions, userRoles)
        ? allCells
        : dropLast(1, allCells)
    }
  })

  return (
    <Table
      pagination={pagination}
      onPageChange={handlePageChange}
      headers={
        isAuthorized(priviledgedRolesActions, userRoles)
          ? headers
          : dropLast(1, headers)
      }
      rows={rows}
      emptyState='Brak produktów do wyświetlenia'
    />
  )
}

export default ProductsTable
