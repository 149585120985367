import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectWarehouses = state => state.warehouses

export const selectWarehousesList = createSelector(
  selectWarehouses,
  propOr([], 'list')
)

export const selectWarehouseState = createSelector(
  selectWarehouses,
  propOr([], 'warehouseState')
)

export const selectWarehouseStatePagination = createSelector(
  selectWarehouses,
  propOr(
    {
      totalPages: 1,
      perPage: 20,
      itemsCount: 0
    },
    'warehouseStatePagination'
  )
)
