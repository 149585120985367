import {
  fetchImportJobsRoutine,
  fetchPendingImportJobsRoutine
} from 'modules/importJobsReport/ducks/actions'

const defaultState = {
  importJobs: [],
  pendingImportJobs: [],
  importJobsPagination: null
}

const importJobsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case fetchImportJobsRoutine.SUCCESS:
      return {
        ...state,
        importJobs: action.payload.data,
        importJobsPagination: {
          itemsCount: action.payload.meta.total,
          totalPages: action.payload.meta.lastPage,
          perPage: action.payload.meta.perPage,
          currentPage: action.payload.meta.currentPage
        }
      }
    case fetchPendingImportJobsRoutine.SUCCESS:
      return {
        ...state,
        pendingImportJobs: action.payload
      }
    default:
      return state
  }
}

export default importJobsReducer
