import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const selectStocktakingOrders = state => state.stocktakingOrders

export const selectStocktakingOrdersList = createSelector(
  selectStocktakingOrders,
  propOr([], 'list')
)

export const selectStocktakingOrdersPagination = createSelector(
  selectStocktakingOrders,
  propOr({
    totalPages: 1,
    perPage: 20,
    itemsCount: 0
  }, 'pagination')
)
