import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentProduct } from 'modules/products/ducks/selectors'
import { flatten, pathOr, propOr } from 'ramda'
import Table from 'components/Table'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'

const LocalizationsTab = () => {
  const product = useSelector(selectCurrentProduct)
  const variants = propOr([], 'variants', product)
  const variantLocations =
    isNotNilOrEmpty(variants) &&
    variants.map(v => {
      if (isNotNilOrEmpty(v.inventory)) {
        return v.inventory.map(element => ({
          variantName: v.name,
          variantEan: v.ean,
          inventory: element
        }))
      } else {
        return []
      }
    })

  //check if there are variants which don't lay on any location or stock unit
  const flattenLocations = flatten(variantLocations)
  const isVariantInventoryEmpty = isNilOrEmpty(flattenLocations)

  const locations = !isVariantInventoryEmpty
    ? flattenLocations
    : propOr([], 'inventory', product)

  const additionalHeaders = isVariantInventoryEmpty
    ? []
    : [{ children: 'Nazwa mixu' }, { children: 'Kod mixu' }]

  const headers = [
    ...additionalHeaders,
    { children: 'Lokalizacja' },
    { children: 'Jednostka magazynowa' },
    { children: 'Liczba produktów' },
    { children: 'Zaalokowane' }
  ]

  const rows = locations.map(row => {
    const variantInventory = propOr(null, 'inventory', row)
    const temp = variantInventory || row
    const type = propOr('', 'inventorableType', variantInventory || row)
    const isLocation = type.includes('Location')

    const locationName = isLocation
      ? pathOr('---', ['inventorable', 'name'], temp)
      : pathOr('---', ['inventorable', 'location', 'name'], temp)

    const stockUnitPrefix = pathOr('', ['inventorable', 'prefix'], temp)
    const stockUnitCode = pathOr('', ['inventorable', 'code'], temp)

    const basicCells = [
      { children: locationName },
      {
        children: isLocation ? '-' : `${stockUnitPrefix}-${stockUnitCode}`
      },
      { children: pathOr('---', ['quantity'], temp) },
      { children: pathOr('---', ['allocatedQuantity'], temp) }
    ]

    const additionalCells = variantInventory
      ? [
          { children: propOr('---', 'variantName', row) },
          { children: propOr('---', 'variantEan', row) }
        ]
      : []

    return {
      redirectPath: isLocation
        ? `/locations/${pathOr('', ['inventorable', 'id'], temp)}`
        : `/stock-units/${pathOr('', ['inventorable', 'id'], temp)}`,
      cells: variantInventory ? [...additionalCells, ...basicCells] : basicCells
    }
  })

  return (
    <div>
      <Table
        headers={headers}
        rows={rows}
        emptyState='Produkt nie znajduje się w żadnej lokalizacji'
      />
    </div>
  )
}

export default LocalizationsTab
